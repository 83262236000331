import { Button, Col, Row, Spin } from 'antd'
import dayjs from 'dayjs'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import approvedWithdrawalImage from '../../../assets/images/approved-withdrawal.png'
import errorMarkImage from '../../../assets/images/error-mark.png'
import noWithdrawalsImage from '../../../assets/images/no-withdrawals.png'
import BasicModal from '../../../atoms/BasicModal'
import StateItem from '../../../atoms/StateItem'
import { RootState } from '../../../redux'
import { IEmployeesLoadDetailPayload } from '../../../redux/employees/employeesActions'
import { IWithdrawalsLoadDetailPayload, withdrawalsLoadDetail, withdrawalsUpdateStatus } from '../../../redux/withdrawals/withdrawalsActions'
import { WITHDRAWAL_STATE, USERS_CONTRACT_STATUS } from '../../../utils/enums'
import { formatAsCurrency } from '../../../utils/helpers'
import exclamationMarkImage from '../../../assets/images/exclamation-mark.png'

type IWithdrawalDetailRequest = {}

const WithdrawalDetailRequest: FC<IWithdrawalDetailRequest> = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const isScreenMD = useMediaQuery({ maxWidth: 767 })

	const employee: IEmployeesLoadDetailPayload = useSelector((state: RootState) => state.employees.detail)
	const withdrawal: IWithdrawalsLoadDetailPayload = useSelector((state: RootState) => state.withdrawals.detail)

	const [approveSuccessModalVisible, setApproveSuccessModalVisible] = useState<boolean>(false)
	const [rejectSuccessModalVisible, setRejectSuccessModalVisible] = useState<boolean>(false)
	const [confirmationApproveModalVisible, setConfirmationApproveModalVisible] = useState<boolean>(false)
	const [confirmationRejectModalVisible, setConfirmationRejectModalVisible] = useState<boolean>(false)

	const handleChangeStatus = (status: WITHDRAWAL_STATE.APPROVED | WITHDRAWAL_STATE.REJECTED) => {
		if (!withdrawal.record?.id) return
		dispatch(
			withdrawalsUpdateStatus(withdrawal.record.id, status, (newWithdrawalID) => {
				dispatch(withdrawalsLoadDetail(newWithdrawalID))
				setConfirmationApproveModalVisible(false)
				setConfirmationRejectModalVisible(false)
			})
		)
	}

	const getCustomLabel = (
		state: string | null | undefined,
		autoapprovalRejectionReason: string | null | undefined,
		autoApproved: boolean | null | undefined
	) => {
		if (state === 'PENDING') {
			return autoapprovalRejectionReason ? 'Pending (Auto-Rejected)' : 'Pending (Not processed)'
		}
		if (state === 'APPROVED') {
			return autoApproved ? 'Auto-Approved' : 'Man-Approved'
		}
		return null
	}

	useEffect(() => {
		if (!employee.record?.withdrawalID) return

		dispatch(withdrawalsLoadDetail(employee.record.withdrawalID))
	}, [dispatch, employee])

	if ((withdrawal.isLoading || employee.isLoading) && (!employee.record?.withdrawalID || !withdrawal.record)) {
		return (
			<div className={'bg-white p-4 lg:p-6 rounded-md mb-6 flex justify-center items-center min-h-[272px]'}>
				<Spin size={'large'} />
			</div>
		)
	}

	if (!employee.record?.withdrawalID || !withdrawal.record) {
		return (
			<div className={'bg-white p-4 lg:p-6 rounded-md mb-6 min-h-[272px]'}>
				<div className={'flex flex-col items-center gap-6 p-4'}>
					<h2 className={'text-26 font-500 text-gray-900 opacity-50%'}>{t('loc:admin|No New Requests')}</h2>
					<img src={noWithdrawalsImage} alt={''} width={145} className={'opacity-50%'} />
				</div>
			</div>
		)
	}

	return (
		<>
			<div className={'bg-white p-4 lg:p-6 rounded-md mb-6 min-h-[272px]'}>
				<h2 className={'flex items-baseline flex-wrap gap-x-3 text-purple-base mb-6 lg:mb-10'}>
					<span className={'text-20 lg:text-22 font-700 whitespace-nowrap'}>{t('loc:admin|Withdrawal Request')}</span>
					<span className={'text-13 lg:text-15 font-400 whitespace-nowrap'}>{withdrawal.record.id}</span>
				</h2>
				<Row gutter={[32, 16]} className={'mb-8'}>
					<Col xs={24} sm={12} lg={6}>
						<h3 className={'text-18 lg:text-20 font-600 text-gray-500 lg:mb-1'}>{t('loc:admin|Received')}</h3>
						<span className={'text-15 font-400'}>{`${dayjs(withdrawal.record.createdDate).format('D MMMM YYYY - HH:mm')}h`}</span>
					</Col>
					<Col xs={24} sm={12} lg={6}>
						<h3 className={'text-18 lg:text-20 font-600 text-gray-500 lg:mb-1'}>{t('loc:general|Amount')}</h3>
						<span className={'text-15 font-400'}>{formatAsCurrency(withdrawal.record.amount)}</span>
					</Col>
					<Col xs={24} sm={12} lg={6}>
						<h3 className={'text-18 lg:text-20 font-600 text-gray-500 lg:mb-1'}>{t('loc:admin|Contract')}</h3>
						<span className={'text-15 font-400'}>
							<span
								className={`inline-flex items-center whitespace-nowrap before:w-2 before:h-2 before:rounded-full before:mr-3 before:flex-shrink-0 ${
									withdrawal.record.employee.contractStatus !== USERS_CONTRACT_STATUS.COMPLETED ? 'before:bg-error' : 'before:bg-success'
								}`}
							>
								{t('loc:general|{{label}}', { label: withdrawal.record.employee.contractStatus })}
							</span>
						</span>
					</Col>
					<Col xs={24} sm={12} lg={6}>
						<h3 className={'text-18 lg:text-20 font-600 text-gray-500 lg:mb-1'}>{t('loc:admin|Status')}</h3>
						<span className={'text-15 font-400'}>
							<StateItem
								state={withdrawal.record.state}
								customLabel={getCustomLabel(
									withdrawal.record.state,
									withdrawal.record.autoapprovalRejectionReason,
									withdrawal.record.autoApproved
								)}
							/>
						</span>
					</Col>
				</Row>
				{withdrawal.record.autoapprovalRejectionReason && (
					<div className={'rejection-reason'}>
						<h3 className={'text-18 lg:text-20 font-600 text-gray-500 lg:mb-1'}>{t('loc:admin|Rejection Reason')}</h3>
						<p>{withdrawal.record.autoapprovalRejectionReason}</p>
					</div>
				)}
				<div className={'flex justify-end items-center flex-wrap gap-x-6 gap-y-2'}>
					<Button
						type={'ghost'}
						shape={'round'}
						block={isScreenMD}
						disabled={withdrawal.record.state === WITHDRAWAL_STATE.REJECTED}
						onClick={() => setConfirmationRejectModalVisible(true)}
					>
						{t('loc:admin|Reject')}
					</Button>
					{withdrawal.record.employee.contractStatus === USERS_CONTRACT_STATUS.COMPLETED && (
						<Button
							type={'primary'}
							shape={'round'}
							block={isScreenMD}
							disabled={withdrawal.record.state === WITHDRAWAL_STATE.APPROVED}
							onClick={() => setConfirmationApproveModalVisible(true)}
						>
							{t('loc:admin|Approve')}
						</Button>
					)}
				</div>
			</div>

			<BasicModal visible={approveSuccessModalVisible} onClose={() => setApproveSuccessModalVisible(false)} width={'30rem'}>
				<img src={approvedWithdrawalImage} width={150} alt={''} className={'self-center mb-6'} />
				<h4 className={'text-center text-28 font-700 leading-sm mb-4'}>
					{t('loc:admin|Withdrawal')}
					<br />
					{t('loc:admin|request approved')}
				</h4>
				<p className={'text-center text-15 font-400 mb-6'}>
					{t('loc:admin|You have approved the Withdrawal')}
					<br />
					{`#${withdrawal.record.id}`}
					<br />
					{`${t('loc:admin|of')} ${withdrawal.record.employee.firstName} ${withdrawal.record.employee.lastName}.`}
				</p>
				<Button type={'primary'} shape={'round'} onClick={() => setApproveSuccessModalVisible(false)}>
					{t('loc:general|Okay')}
				</Button>
			</BasicModal>

			<BasicModal visible={rejectSuccessModalVisible} onClose={() => setRejectSuccessModalVisible(false)} width={'30rem'}>
				<img src={errorMarkImage} width={150} alt={''} className={'self-center mb-6'} />
				<h4 className={'text-center text-28 font-700 leading-sm mb-4'}>
					{t('loc:admin|Withdrawal')}
					<br />
					{t('loc:admin|request rejected')}
				</h4>
				<p className={'text-center text-15 font-400 mb-6'}>
					{t('loc:admin|You have rejected the Withdrawal')}
					<br />
					{`#${withdrawal.record.id}`}
					<br />
					{`${t('loc:admin|of')} ${withdrawal.record.employee.firstName} ${withdrawal.record.employee.lastName}.`}
				</p>
				<Button type={'primary'} shape={'round'} onClick={() => setRejectSuccessModalVisible(false)}>
					{t('loc:general|Okay')}
				</Button>
			</BasicModal>

			<BasicModal visible={confirmationApproveModalVisible} onClose={() => setConfirmationApproveModalVisible(false)} width={'30rem'}>
				<img src={exclamationMarkImage} width={36} alt={''} className={'self-center mb-6'} />
				<p className={'text-center text-15 font-400 mb-6'}>
					{`${t('loc:admin|Are you sure you want to')} `} <b>{t('loc:admin|approve')}</b> {t('loc:admin|Withdrawal')}
				</p>

				<Button type={'primary'} shape={'round'} onClick={() => handleChangeStatus(WITHDRAWAL_STATE.APPROVED)} className={'mb-4'}>
					{t('loc:admin|Approve')}
				</Button>
				<Button shape={'round'} onClick={() => setConfirmationApproveModalVisible(false)}>
					{t('loc:general|Cancel')}
				</Button>
			</BasicModal>

			<BasicModal visible={confirmationRejectModalVisible} onClose={() => setConfirmationRejectModalVisible(false)} width={'30rem'}>
				<img src={exclamationMarkImage} width={36} alt={''} className={'self-center mb-6'} />
				<p className={'text-center text-15 font-400 mb-6'}>
					{`${t('loc:admin|Are you sure you want to')} `} <b>{t('loc:admin|reject')}</b> {t('loc:admin|Withdrawal')}
				</p>

				<Button type={'primary'} shape={'round'} onClick={() => handleChangeStatus(WITHDRAWAL_STATE.REJECTED)} className={'mb-4'}>
					{t('loc:admin|Reject')}
				</Button>
				<Button shape={'round'} onClick={() => setConfirmationRejectModalVisible(false)} className={'mb-4'}>
					{t('loc:general|Cancel')}
				</Button>
			</BasicModal>
		</>
	)
}

export default WithdrawalDetailRequest
