import { Empty, Spin, Table, TableColumnProps } from 'antd'
import dayjs from 'dayjs'
import { FC, useEffect, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import StateItem from '../../../atoms/StateItem'
import { RootState } from '../../../redux'
import { IEmployeesLoadDetailPayload } from '../../../redux/employees/employeesActions'
import { IWithdrawalsLoadListByUserPayload, withdrawalsLoadListByUser } from '../../../redux/withdrawals/withdrawalsActions'
import { IPaginationResponse, IWithdrawal } from '../../../types/interfaces'
import { formatAsCurrency } from '../../../utils/helpers'

type IWithdrawalDetailHistory = {}

export interface IWithdrawalDetailHistoryFilterValues {
	page?: IPaginationResponse['page']
	limit?: IPaginationResponse['limit']
	exceptWithdrawalIDS?: string[]
}

export const withdrawalDetailHistoryFilterDefaultState: IWithdrawalDetailHistoryFilterValues = {
	page: 1,
	limit: 10
}

const WithdrawalDetailHistory: FC<IWithdrawalDetailHistory> = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const employee: IEmployeesLoadDetailPayload = useSelector((state: RootState) => state.employees.detail)
	const withdrawals: IWithdrawalsLoadListByUserPayload = useSelector((state: RootState) => state.withdrawals.listByUser)
	const [dataSource, setDataSource] = useState<IWithdrawalsLoadListByUserPayload['records']>(withdrawals.records)
	const [filter, setFilter] = useState<IWithdrawalDetailHistoryFilterValues>(withdrawalDetailHistoryFilterDefaultState)

	const columns: TableColumnProps<IWithdrawal>[] = [
		{
			title: t('loc:admin|Received'),
			key: 'createdDate',
			dataIndex: 'createdDate',
			render: (value: IWithdrawal['createdDate']) => <span className={'whitespace-nowrap'}>{dayjs(value).format('DD.MM.YYYY - HH:mm')}</span>
		},
		{
			title: t('loc:general|Amount'),
			key: 'amount',
			dataIndex: 'amount',
			render: (value: IWithdrawal['amount']) => <span className={'whitespace-nowrap'}>{formatAsCurrency(value)}</span>
		},
		{
			title: t('loc:admin|State'),
			key: 'state',
			dataIndex: 'state',
			render: (value: IWithdrawal['state'], record: IWithdrawal) => {
				let customLabel = null
				if (value === 'APPROVED') {
					customLabel = record.autoApproved === true ? 'Auto-Approved' : 'Man-Approved'
				} else if (value === 'PENDING') {
					customLabel = record.autoapprovalRejectionReason ? 'Pending(Auto-Rejected)' : 'Pending(Not processed)'
				}
				return <StateItem state={value} customLabel={customLabel} />
			},
			align: 'right'
		}
	]

	useEffect(() => {
		if (!employee.record) return
		dispatch(
			withdrawalsLoadListByUser(employee.record.id, {
				...filter,
				exceptWithdrawalIDS: employee.record.withdrawalID ? [employee.record.withdrawalID] : undefined
			})
		)
	}, [dispatch, employee, filter])

	useLayoutEffect(() => {
		setDataSource(withdrawals.records)
	}, [withdrawals.records])

	return (
		<div className={'bg-white p-4 lg:p-6 rounded-md mb-6'}>
			<h2 className={'text-20 lg:text-22 font-700 mb-6'}>{t('loc:admin|Employee Withdraw History')}</h2>
			<Table
				scroll={{ x: true }}
				loading={{
					indicator: <Spin size={'large'} />,
					spinning: withdrawals.isLoading
				}}
				columns={columns}
				dataSource={dataSource}
				rowKey={(record) => record.id}
				locale={{ emptyText: <Empty description={t('loc:general|No Data')} /> }}
				pagination={{
					position: ['bottomCenter'],
					showSizeChanger: false,
					hideOnSinglePage: true,
					total: withdrawals.pagination?.totalCount,
					current: withdrawals.pagination?.page,
					pageSize: withdrawals.pagination?.limit
				}}
				onChange={(pagination) => setFilter({ ...filter, page: pagination.current })}
				className={'withdrawals-history-table'}
			/>
		</div>
	)
}

export default WithdrawalDetailHistory
